/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-main-sidebar-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.app-main-sidebar-menu.ant-menu,
.app-main-sidebar-menu .ant-menu-sub.ant-menu-inline {
  background-color: inherit;
}
.app-main-sidebar-menu .ant-menu-item-group-title {
  padding-left: 31px;
  padding-right: 12px;
  color: inherit;
  font-weight: 600;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
[dir=rtl] .app-main-sidebar-menu .ant-menu-item-group-title {
  padding-left: 12px;
  padding-right: 31px;
}
.app-main-sidebar-menu .ant-menu-item-group-list .ant-menu-item,
.app-main-sidebar-menu .ant-menu-item-group-list .ant-menu-submenu-title {
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: 30px !important;
  padding-right: 12px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
[dir=rtl] .app-main-sidebar-menu .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .app-main-sidebar-menu .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 30px !important;
}
.app-main-sidebar-menu .ant-menu-item .ant-menu-item-icon + span,
.app-main-sidebar-menu .ant-menu-submenu-title .ant-menu-item-icon + span,
.app-main-sidebar-menu .ant-menu-item .anticon + span,
.app-main-sidebar-menu .ant-menu-submenu-title .anticon + span {
  margin-left: 16px;
}
[dir=rtl] .app-main-sidebar-menu .ant-menu-item .ant-menu-item-icon + span,
[dir=rtl] .app-main-sidebar-menu .ant-menu-submenu-title .ant-menu-item-icon + span,
[dir=rtl] .app-main-sidebar-menu .ant-menu-item .anticon + span,
[dir=rtl] .app-main-sidebar-menu .ant-menu-submenu-title .anticon + span {
  margin-left: 0;
  margin-right: 16px;
}
.app-main-sidebar-menu .ant-menu-submenu .ant-menu-submenu-title,
.app-main-sidebar-menu .ant-menu-submenu-title > .ant-menu-title-content,
.app-main-sidebar-menu .ant-menu-item .ant-menu-title-content > a {
  display: flex;
  align-items: center;
  background-color: inherit;
  font-weight: 500;
}
.app-main-sidebar-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: inherit;
}
.app-main-sidebar-menu .ant-menu-item-icon svg {
  display: block;
}
.app-main-sidebar-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.app-main-sidebar-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 46px !important;
}
[dir=rtl] .app-main-sidebar-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item,
[dir=rtl] .app-main-sidebar-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
  padding-right: 46px !important;
}
.app-main-sidebar-menu .ant-menu-item .ant-menu-item-icon,
.app-main-sidebar-menu .ant-menu-submenu-title .ant-menu-item-icon,
.app-main-sidebar-menu .ant-menu-item .anticon,
.app-main-sidebar-menu .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.app-main-sidebar-menu.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-rtl .ant-menu-item .anticon,
.app-main-sidebar-menu.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: 0;
  margin-left: 0;
}
.app-main-sidebar-menu.ant-menu.ant-menu-dark,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-sub,
.app-main-sidebar-menu.ant-menu.ant-menu-dark .ant-menu-sub {
  color: inherit;
  background: none;
}
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item > a,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item > span > a {
  color: inherit;
}
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item-selected .anticon,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.app-main-sidebar-menu.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: inherit;
}
.app-main-sidebar-menu .ant-menu-item a,
.app-main-sidebar-menu.ant-menu-light .ant-menu-item:hover,
.app-main-sidebar-menu.ant-menu-light .ant-menu-item-active,
.app-main-sidebar-menu.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.app-main-sidebar-menu.ant-menu-light .ant-menu-submenu-active,
.app-main-sidebar-menu.ant-menu-light .ant-menu-submenu-title:hover {
  color: inherit;
}
.app-main-sidebar-menu.ant-menu-inline-collapsed {
  width: auto;
}
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  min-height: 0;
}
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: calc(100% - 16px);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
[dir=rtl] .app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .ant-menu-item-icon + span,
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-item .anticon + span,
.app-main-sidebar-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .anticon + span {
  width: 0;
  margin: 0;
}
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.app-main-sidebar-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.menu-rounded .ant-menu-item a {
  color: inherit;
}
.menu-rounded .ant-menu-item a:hover,
.menu-rounded .ant-menu-item a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-item:hover a:hover,
.menu-rounded .ant-menu-item:hover a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-item-active,
.menu-rounded .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: inherit;
}
.menu-rounded .ant-menu-submenu-open > .ant-menu-submenu-title {
  color: inherit;
}
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.menu-rounded.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 1;
  color: inherit;
}
.menu-rounded .ant-menu-sub.ant-menu-inline {
  background: none;
}
.menu-rounded .ant-menu-item-group-list .ant-menu-item,
.menu-rounded .ant-menu-item-group-list .ant-menu-submenu-title,
.menu-rounded .ant-menu.ant-menu-inline .ant-menu-item {
  width: calc(100% - 16px);
  margin-right: 16px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
[dir=rtl] .menu-rounded .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .menu-rounded .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .menu-rounded .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 0;
  margin-left: 16px;
  border-radius: 30px 0 0 30px;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: inherit;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: inherit;
}
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover,
.menu-rounded.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:focus {
  color: inherit;
}
.menu-rounded .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.menu-rounded .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-vertical .ant-menu-item::after,
.menu-rounded.ant-menu-vertical-left .ant-menu-item::after,
.menu-rounded.ant-menu-vertical-right .ant-menu-item::after,
.menu-rounded.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.menu-rounded .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded .ant-menu-submenu-arrow:before,
.menu-rounded .ant-menu-submenu-arrow:after {
  color: inherit;
  width: 8px;
  height: 1.8px;
}
.menu-rounded .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.menu-rounded .ant-menu-submenu-title .ant-menu-item-icon svg,
.menu-rounded .ant-menu-submenu-title .anticon {
  display: block;
}
[dir=rtl] .menu-rounded .ant-menu-submenu-title .ant-menu-item-icon {
  margin-right: 0;
}
.menu-rounded.ant-menu-dark .ant-menu-item a {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
}
.menu-rounded.ant-menu-dark .ant-menu-item:hover a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item-active a:hover,
.menu-rounded.ant-menu-dark .ant-menu-item:hover a:focus,
.menu-rounded.ant-menu-dark .ant-menu-item-active a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item-group-title {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-item-active,
.menu-rounded.ant-menu-dark .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: transparent;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.menu-rounded.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.menu-rounded.ant-menu-dark .ant-menu-submenu > .ant-menu-submenu-title:hover > .ant-menu-submenu-expand-icon,
.menu-rounded.ant-menu-dark .ant-menu-submenu > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed {
  width: 100% !important;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.menu-rounded.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  margin-left: 0;
  border-radius: 0;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-active,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-title:hover {
  color: inherit;
  background-color: transparent;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:focus {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-item-active,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-title:hover {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: inherit;
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before:hover,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:after:focus,
.menu-rounded.ant-menu-inline-collapsed.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow:before:focus {
  color: inherit;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-title {
  min-height: 0;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: calc(100% - 16px);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .menu-rounded.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-title .ant-menu-item-icon + span,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-item .anticon + span,
.menu-rounded.ant-menu-inline-collapsed .ant-menu-submenu-title .anticon + span {
  width: 0;
  margin: 0;
}
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.menu-rounded.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.rounded-menu-reverse .ant-menu-item-group-list .ant-menu-item,
.rounded-menu-reverse .ant-menu-item-group-list .ant-menu-submenu-title,
.rounded-menu-reverse .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 0;
  margin-left: 16px;
  border-radius: 30px 0 0 30px;
  padding-left: 14px !important;
}
[dir=rtl] .rounded-menu-reverse .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .rounded-menu-reverse .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .rounded-menu-reverse .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 16px;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
  padding-left: 12px !important;
  padding-right: 14px !important;
}
.rounded-menu-reverse .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.rounded-menu-reverse .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 50px !important;
}
[dir=rtl] .rounded-menu-reverse .ant-menu-sub.ant-menu-inline > .ant-menu-item,
[dir=rtl] .rounded-menu-reverse .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
  padding-right: 50px !important;
}
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-title {
  min-height: 0;
}
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: calc(100% - 16px);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
[dir=rtl] .rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-submenu-title .ant-menu-item-icon + span,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-item .anticon + span,
.rounded-menu-reverse.ant-menu-inline-collapsed .ant-menu-submenu-title .anticon + span {
  width: 0;
  margin: 0;
}
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.rounded-menu-reverse.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.standard-menu .ant-menu-item-group-list .ant-menu-item,
.standard-menu .ant-menu-item-group-list .ant-menu-submenu-title,
.standard-menu .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
  padding-left: 22px !important;
}
[dir=rtl] .standard-menu .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .standard-menu .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .standard-menu .ant-menu.ant-menu-inline .ant-menu-item {
  padding-left: 12px !important;
  padding-right: 22px !important;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
}
.standard-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.standard-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 60px !important;
}
[dir=rtl] .standard-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item,
[dir=rtl] .standard-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 60px !important;
}
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.standard-menu.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  min-height: 0;
}
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: calc(100% - 16px);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
}
[dir=rtl] .standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .standard-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.standard-menu.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.standard-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .ant-menu-item-icon + span,
.standard-menu.ant-menu-inline-collapsed .ant-menu-item .anticon + span,
.standard-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .anticon + span {
  width: 0;
  margin: 0;
}
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.standard-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 20px;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item {
  width: calc(100% - 8px);
  margin-right: 0;
  margin-left: 8px;
  border-radius: 30px 0 0 30px;
  position: relative;
  overflow: inherit;
}
[dir=rtl] .curved-menu .ant-menu-item-group-list .ant-menu-item,
[dir=rtl] .curved-menu .ant-menu-item-group-list .ant-menu-submenu-title,
[dir=rtl] .curved-menu .ant-menu.ant-menu-inline .ant-menu-item {
  margin-right: 8px;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item:before,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title:before,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item:before,
.curved-menu .ant-menu-item-group-list .ant-menu-item:after,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title:after,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item:after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 1;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 50%;
  opacity: 0;
  display: block;
  border-right: 0 none;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item:before,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title:before,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item:before {
  top: -40px;
  box-shadow: 30px 30px 0 10px transparent;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item:after,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title:after,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item:after {
  bottom: -40px;
  box-shadow: 30px -30px 0 10px transparent;
  top: auto;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item.ant-menu-item-selected:before,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title.ant-menu-item-selected:before,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item.ant-menu-item-selected:before {
  box-shadow: 30px 30px 0 10px #F4F7FE;
  opacity: 1;
}
.curved-menu .ant-menu-item-group-list .ant-menu-item.ant-menu-item-selected:after,
.curved-menu .ant-menu-item-group-list .ant-menu-submenu-title.ant-menu-item-selected:after,
.curved-menu .ant-menu.ant-menu-inline .ant-menu-item.ant-menu-item-selected:after {
  box-shadow: 30px -30px 0 10px #F4F7FE;
  opacity: 1;
}
.curved-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-item,
.curved-menu.ant-menu-inline-collapsed .ant-menu-item-group-list .ant-menu-submenu-title,
.curved-menu.ant-menu-inline-collapsed .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.bg-color-menu.ant-menu.ant-menu-dark,
.bg-color-menu.ant-menu-dark .ant-menu-sub,
.bg-color-menu.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
}
