.app-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
@primary-color: #0A8FDC;